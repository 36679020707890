import http from "../http-common";

class SubmissionService {
  getAllSubmissions(sortBy = "", sortOrder = "", searchQuery = "", currentPage = "", pageSize = "") {
    console.log(sortBy, sortOrder, searchQuery, currentPage, pageSize)
    return http.get(`/submission/submissions?page=${currentPage-1}&size=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&searchQuery=${searchQuery}`);
  }

  get(submissionId) {
  	return http.get(`/submission/${submissionId}`);
  }

  findByField(matchData) {
  	return http.get(`/submission?field=${matchData}`);
  }

  addSubmission(data) {
    return http.post("/submission/addSubmission", data);
  }

  update(data) {
  	return http.post("/submission/updateSubmission", data);
  }
}

export default new SubmissionService();
