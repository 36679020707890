<template>
    <div>
        <div class="row card-container">
            <div class="col candidate-card" v-for="candidate in candidates" :key="candidate.candidateId">
                <img class="candidate-image" :alt="candidate.candidateNumber" :src="`/images/${candidate.imageFile}`" />
                <!-- <img class="candidate-image" :alt="candidate.candidateNumber"
                    :src="`https://source.unsplash.com/collection/928423/230x250`" /> -->
                <div class="candidate-info">
                    <div class="candidate-name">{{ candidate.candidateNumber }}</div>
                    <div class="candidate-details">
                        <p>{{ candidate.candidateNumber }}</p>
                        <p>{{ candidate.locationNumber }}</p>
                        <p>{{ candidate.operatorNumber }}</p>
                        <a :href="candidate.candidateNumber" target="_blank" class="candidate-link">candidate Webpage</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>

export default {
    components: {
    },
    props: {
        candidates: Array,
    },

    methods: {

    },
};
</script>
  
<style scoped>
.card-container {
    display: flex;
    column-gap: 15px;
    flex-wrap: wrap;
    margin: 5px;

}

.card {
    max-width: 230px;

}

.candidate-picture-view {
    margin-bottom: 15px;
    /* Separate each row from the content below */
}

.candidate-card {
    max-width: 250px;
    min-width: 250px;
    border-radius: 4px;
    padding: 0px;
    margin-bottom: 25px;
    /* Separate each card */
}

.candidate-image {
    width: 250px;
    height: 200px;
    /* object-fit: contain; */
    border-radius: 4px;
}

.candidate-info {
    margin-top: 0px;
}

.candidate-name {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 2px;
    line-height: 1.2;
}

.candidate-details p {
    margin-top: 0px;
    text-align: left;
}

.candidate-link {
    display: inline;
    margin-top: 5px;
    color: #1890ff;
}
</style>
  