import http from "../http-common";

class MatchService {
  getAllMatches(sortBy = "", sortOrder = "", searchQuery = "", currentPage = "", pageSize = "") {
    console.log(sortBy, sortOrder, searchQuery, currentPage, pageSize)
    return http.get(`/match/matches?page=${currentPage-1}&size=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&searchQuery=${searchQuery}`);
  }

  get(matchId) {
  	return http.get(`/match/${matchId}`);
  }

  findByField(matchData) {
  	return http.get(`/match?field=${matchData}`);
  }

  addMatch(data) {
    return http.post("/match/addMatch", data);
  }

  update(data) {
  	return http.post("/match/updateMatch", data);
  }
}

export default new MatchService();
