<template>
  <div class="content">
    <!-- search bar -->
    <div class="row my-3">
      <div class="col-8"></div>
      <div class="col-4">
        <!-- Header Search Input -->
        <a-input-search class="header-search" :class="searchLoading ? 'loading' : ''" placeholder="Search by BusinessUnit#, Location#, Operator#, City, State, FirstName, LastName…"
          @search="onSearch" :loading='searchLoading' v-model="searchQuery">
          <svg slot="prefix" width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.29583 13.5892 10.4957 12.8907 11.4765L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L11.4765 12.8907C10.4957 13.5892 9.29583 14 8 14C4.68629 14 2 11.3137 2 8Z"
              fill="#111827" />
          </svg>
        </a-input-search>
        <!-- / Header Search Input -->
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card>
          <template slot="header">
            <div class="row justify-content-between align-items-between mx-3">

              <h4 class="card-title">Submissions</h4>
              
              <div>
                  <base-button class="btn btn-primary" @click="modalSubmissions = true">Add</base-button>
              </div>
              
              <modal :show.sync="modalSubmissions">
                <template slot="header">
                  <h5 class="modal-title" id="exampleModalLabel">Add Submission</h5>
                </template>
                <div>
                  <form @submit.prevent>
  <base-input label="SubmissionId" type="text" placeholder="Enter SubmissionId" v-model="submissionsData.submissionId"></base-input>
  <base-input label="SubmissionNumber" type="text" placeholder="Enter SubmissionNumber" v-model="submissionsData.submissionNumber"></base-input>
  <base-input label="JobId" type="text" placeholder="Enter JobId" v-model="submissionsData.jobId"></base-input>
  <base-input label="CandidateId" type="text" placeholder="Enter CandidateId" v-model="submissionsData.candidateId"></base-input>
  <base-input label="ClientName" type="text" placeholder="Enter ClientName" v-model="submissionsData.clientName"></base-input>
  <base-input label="JobName" type="text" placeholder="Enter JobName" v-model="submissionsData.jobName"></base-input>
  <base-input label="CandidateName" type="text" placeholder="Enter CandidateName" v-model="submissionsData.candidateName"></base-input>
  <base-input label="CandidateType" type="text" placeholder="Enter CandidateType" v-model="submissionsData.candidateType"></base-input>
  <base-input label="StartDate" type="text" placeholder="Enter StartDate" v-model="submissionsData.startDate"></base-input>
  <base-input label="EndDate" type="text" placeholder="Enter EndDate" v-model="submissionsData.endDate"></base-input>
  <base-input label="ImageFile" type="text" placeholder="Enter ImageFile" v-model="submissionsData.imageFile"></base-input>
  <base-input label="JobNumber" type="text" placeholder="Enter JobNumber" v-model="submissionsData.jobNumber"></base-input>
  <base-input label="CityState" type="text" placeholder="Enter CityState" v-model="submissionsData.cityState"></base-input>
  <base-input label="Active" type="text" placeholder="Enter Active" v-model="submissionsData.active"></base-input>
  <base-input label="Description" type="text" placeholder="Enter Description" v-model="submissionsData.description"></base-input>
  <base-input label="LastModified" type="text" placeholder="Enter LastModified" v-model="submissionsData.lastModified"></base-input>
  <base-input label="CreatedOn" type="text" placeholder="Enter CreatedOn" v-model="submissionsData.createdOn"></base-input>
  <base-input label="CreatedBy" type="text" placeholder="Enter CreatedBy" v-model="submissionsData.createdBy"></base-input>
  <base-input label="CreatedById" type="text" placeholder="Enter CreatedById" v-model="submissionsData.createdById"></base-input>
  <base-input label="SensitivityLevel" type="text" placeholder="Enter SensitivityLevel" v-model="submissionsData.sensitivityLevel"></base-input>
                  </form>
                </div>
                <template slot="footer">
                  <base-button type="primary" @click="submitSubmissions()">Save</base-button>
                </template>
              </modal>
            </div>
          </template>


          <!-- Conditionally render the view based on the 'isTableView' flag -->
          <div v-if="isTableView">
            <!-- Render the existing Table View -->
            <a-table :columns="columns" :data-source="submissions" :row-key="record => record.SubmissionId" :pagination="pagination"
              :loading="searchLoading" @change="onTableChange" :scroll="{ x: 'max-content' }">
            </a-table>
          </div>
          <div v-else>
            <!-- Render the Picture View  -->
            <SubmissionPictureView :submissions="submissions" />
          </div>

        </card>
      </div>
    </div>

  </div>
</template>

<script>
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/Inputs/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/index";
import SubmissionService from "../services/SubmissionService";
import { ASelect, ASelectOption, AButton, Table, Pagination } from "ant-design-vue";
import SubmissionPictureView from './SubmissionPictureView.vue';


const submissionsColumns = [
  "submissionId",
  "year",
  "date",
  "competitionId",
  "submissionId"
]

export default {
  components: {
    Card,
    Modal,
    BaseButton,
    BaseInput,
    Table,

    Pagination,
    SubmissionPictureView
  },

  data() {
    return {
      modalSubmissions: false,
        isTableView: true,

      columns: [
        {
          title: 'SubmissionId',
          dataIndex: 'submissionId',
          visible: false,
          //scopedSlots: { customRender: 'submissionId' },
          //sorter: (a, b) => a.submissionId - b.submissionId,
          //sorter: (a, b) => a.submissionId.localeCompare(b.submissionId),
        },
        {
          title: 'SubmissionNumber',
          dataIndex: 'submissionNumber',
          visible: true,
          //scopedSlots: { customRender: 'submissionNumber' },
          //sorter: (a, b) => a.submissionNumber - b.submissionNumber,
          //sorter: (a, b) => a.submissionNumber.localeCompare(b.submissionNumber),
        },
        {
          title: 'JobId',
          dataIndex: 'jobId',
          visible: false,
          //scopedSlots: { customRender: 'jobId' },
          //sorter: (a, b) => a.jobId - b.jobId,
          //sorter: (a, b) => a.jobId.localeCompare(b.jobId),
        },
        {
          title: 'CandidateId',
          dataIndex: 'candidateId',
          visible: false,
          //scopedSlots: { customRender: 'candidateId' },
          //sorter: (a, b) => a.candidateId - b.candidateId,
          //sorter: (a, b) => a.candidateId.localeCompare(b.candidateId),
        },
        {
          title: 'ClientName',
          dataIndex: 'clientName',
          visible: true,
          //scopedSlots: { customRender: 'clientName' },
          //sorter: (a, b) => a.clientName - b.clientName,
          //sorter: (a, b) => a.clientName.localeCompare(b.clientName),
        },
        {
          title: 'JobName',
          dataIndex: 'jobName',
          visible: true,
          //scopedSlots: { customRender: 'jobName' },
          //sorter: (a, b) => a.jobName - b.jobName,
          //sorter: (a, b) => a.jobName.localeCompare(b.jobName),
        },
        {
          title: 'CandidateName',
          dataIndex: 'candidateName',
          visible: true,
          //scopedSlots: { customRender: 'candidateName' },
          //sorter: (a, b) => a.candidateName - b.candidateName,
          //sorter: (a, b) => a.candidateName.localeCompare(b.candidateName),
        },
        {
          title: 'CandidateType',
          dataIndex: 'candidateType',
          visible: false,
          //scopedSlots: { customRender: 'candidateType' },
          //sorter: (a, b) => a.candidateType - b.candidateType,
          //sorter: (a, b) => a.candidateType.localeCompare(b.candidateType),
        },
        {
          title: 'StartDate',
          dataIndex: 'startDate',
          visible: false,
          //scopedSlots: { customRender: 'startDate' },
          //sorter: (a, b) => a.startDate - b.startDate,
          //sorter: (a, b) => a.startDate.localeCompare(b.startDate),
        },
        {
          title: 'EndDate',
          dataIndex: 'endDate',
          visible: false,
          //scopedSlots: { customRender: 'endDate' },
          //sorter: (a, b) => a.endDate - b.endDate,
          //sorter: (a, b) => a.endDate.localeCompare(b.endDate),
        },
        {
          title: 'ImageFile',
          dataIndex: 'imageFile',
          visible: false,
          //scopedSlots: { customRender: 'imageFile' },
          //sorter: (a, b) => a.imageFile - b.imageFile,
          //sorter: (a, b) => a.imageFile.localeCompare(b.imageFile),
        },
        {
          title: 'JobNumber',
          dataIndex: 'jobNumber',
          visible: true,
          //scopedSlots: { customRender: 'jobNumber' },
          //sorter: (a, b) => a.jobNumber - b.jobNumber,
          //sorter: (a, b) => a.jobNumber.localeCompare(b.jobNumber),
        },
        {
          title: 'CityState',
          dataIndex: 'cityState',
          visible: true,
          //scopedSlots: { customRender: 'cityState' },
          //sorter: (a, b) => a.cityState - b.cityState,
          //sorter: (a, b) => a.cityState.localeCompare(b.cityState),
        },
        {
          title: 'Active',
          dataIndex: 'active',
          visible: false,
          //scopedSlots: { customRender: 'active' },
          //sorter: (a, b) => a.active - b.active,
          //sorter: (a, b) => a.active.localeCompare(b.active),
        },
        {
          title: 'Description',
          dataIndex: 'description',
          visible: false,
          //scopedSlots: { customRender: 'description' },
          //sorter: (a, b) => a.description - b.description,
          //sorter: (a, b) => a.description.localeCompare(b.description),
        },
        {
          title: 'LastModified',
          dataIndex: 'lastModified',
          visible: false,
          //scopedSlots: { customRender: 'lastModified' },
          //sorter: (a, b) => a.lastModified - b.lastModified,
          //sorter: (a, b) => a.lastModified.localeCompare(b.lastModified),
        },
        {
          title: 'CreatedOn',
          dataIndex: 'createdOn',
          visible: false,
          //scopedSlots: { customRender: 'createdOn' },
          //sorter: (a, b) => a.createdOn - b.createdOn,
          //sorter: (a, b) => a.createdOn.localeCompare(b.createdOn),
        },
        {
          title: 'CreatedBy',
          dataIndex: 'createdBy',
          visible: false,
          //scopedSlots: { customRender: 'createdBy' },
          //sorter: (a, b) => a.createdBy - b.createdBy,
          //sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
        },
        {
          title: 'CreatedById',
          dataIndex: 'createdById',
          visible: false,
          //scopedSlots: { customRender: 'createdById' },
          //sorter: (a, b) => a.createdById - b.createdById,
          //sorter: (a, b) => a.createdById.localeCompare(b.createdById),
        },
        {
          title: 'SensitivityLevel',
          dataIndex: 'sensitivityLevel',
          visible: false,
          //scopedSlots: { customRender: 'sensitivityLevel' },
          //sorter: (a, b) => a.sensitivityLevel - b.sensitivityLevel,
          //sorter: (a, b) => a.sensitivityLevel.localeCompare(b.sensitivityLevel),
        },
      ],
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total) => `Total ${total} submissions`,
      },

      submissionsData: {
        submissionId: null,
        year: '',
        date: '',
        competitionId: '',
        submissionId: ''
      },

      submissions: [],


      submissionsTable: {
        columns: [...submissionsColumns],
        data: [],
      },

      // New properties for sorting and searching
      sortBy: 'submissionId',           // Column to sort by
      sortOrder: 'asc',     // Sort order (asc or desc)
      searchQuery: '',      // Search query
      searchLoading: false, // Initialize searchLoading property


    };
  },
  watch: {
    searchQuery: {
      handler: "getAllSubmissions", // Call the fetchData method when searchQuery changes
      immediate: true, // Trigger immediately when the component is mounted
    },
  },

  methods: {

    async submitSubmissions() {
      const currentDate = new Date().getTime();
      this.submissionsData.created = currentDate;

      const jsonData = JSON.stringify(this.submissionsData);
      const res = await SubmissionService.addSubmission(jsonData);

      if (res.status === 200) {
        this.$notify({
          component: NotificationTemplate,
          icon: "tim-icons icon-bell-55",
          type: "success",
          timeout: 3000,
        });

        this.modalSubmissions = false;
        this.getAllSubmissions();
      }
    },

    async getAllSubmissions() {
      this.searchLoading = true; // Set searchLoading to true while fetching data

      const response = await SubmissionService.getAllSubmissions(this.sortBy, this.sortOrder, this.searchQuery, this.pagination.current,
        this.pagination.pageSize
      );
      this.submissions = response.data.content;
      this.pagination.total = response.data.totalElements;
      this.searchLoading = false;

      console.log(this.submissions);
      let submissionsTableData = [];
      for (let i = 0; i < this.submissions.length; i++) {
        submissionsTableData.push({
          id: i,
          submissionId: this.submissions[i].submissionId,
          year: this.submissions[i].year,
          date: this.submissions[i].date,
          competitionId: this.submissions[i].competitionId,
          submissionId: this.submissions[i].submissionId,
        });

      }
      this.searchLoading = false;


    },
    async onTableChange(pagination, filters, sorter) {
      if (sorter && sorter.field && sorter.order) {
        this.sortBy = sorter.field;
        if (sorter.order == "ascend") {
            this.sortOrder = "asc";
        } else {
            this.sortOrder = "desc";
        }
      }
      if (pagination) {
        this.pagination.current = pagination.current;
        this.pagination.pageSize = pagination.pageSize;
      }

      await this.getAllSubmissions();
    },

    onSearch(value) {
      console.log(value);
      this.searchQuery = value; // Update searchQuery when the user types
    },

    toggleView() {
      this.isTableView = !this.isTableView;
    },
  },

  mounted() {
    this.getAllSubmissions();
  }
};
</script>

<style>
.modal-dialog {
  margin-top: -300px;
}
</style>
