import http from "../http-common";

class ClientService {
  getAllClients(sortBy = "", sortOrder = "", searchQuery = "", currentPage = "", pageSize = "") {
    console.log(sortBy, sortOrder, searchQuery, currentPage, pageSize)
    return http.get(`/client/clients?page=${currentPage-1}&size=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&searchQuery=${searchQuery}`);
  }

  get(clientId) {
  	return http.get(`/client/${clientId}`);
  }

  findByField(matchData) {
  	return http.get(`/client?field=${matchData}`);
  }

  addClient(data) {
    return http.post("/client/addClient", data);
  }

  update(data) {
  	return http.post("/client/updateClient", data);
  }
}

export default new ClientService();
