<template>
  <div class="content">


  <h5>{{ this.jobId }}</h5>

<div  style="display: inline-flex;">
  <div><input label="JobId" type="text" placeholder="Enter JobId" v-model="jobDetails.jobId"></input></div>
  <div style="width: 5px;"></div>
  <div>JobId<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="JobNumber" type="text" placeholder="Enter JobNumber" v-model="jobDetails.jobNumber"></input></div>
  <div style="width: 5px;"></div>
  <div>JobNumber<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="JobName" type="text" placeholder="Enter JobName" v-model="jobDetails.jobName"></input></div>
  <div style="width: 5px;"></div>
  <div>JobName<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="JobType" type="text" placeholder="Enter JobType" v-model="jobDetails.jobType"></input></div>
  <div style="width: 5px;"></div>
  <div>JobType<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="Role" type="text" placeholder="Enter Role" v-model="jobDetails.role"></input></div>
  <div style="width: 5px;"></div>
  <div>Role<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="Company" type="text" placeholder="Enter Company" v-model="jobDetails.company"></input></div>
  <div style="width: 5px;"></div>
  <div>Company<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="IsContract" type="text" placeholder="Enter IsContract" v-model="jobDetails.isContract"></input></div>
  <div style="width: 5px;"></div>
  <div>IsContract<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="IsFte" type="text" placeholder="Enter IsFte" v-model="jobDetails.isFte"></input></div>
  <div style="width: 5px;"></div>
  <div>IsFte<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="IsFullTime" type="text" placeholder="Enter IsFullTime" v-model="jobDetails.isFullTime"></input></div>
  <div style="width: 5px;"></div>
  <div>IsFullTime<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="IsPartTime" type="text" placeholder="Enter IsPartTime" v-model="jobDetails.isPartTime"></input></div>
  <div style="width: 5px;"></div>
  <div>IsPartTime<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="NumberOfApplicants" type="text" placeholder="Enter NumberOfApplicants" v-model="jobDetails.numberOfApplicants"></input></div>
  <div style="width: 5px;"></div>
  <div>NumberOfApplicants<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="NumberOfRejections" type="text" placeholder="Enter NumberOfRejections" v-model="jobDetails.numberOfRejections"></input></div>
  <div style="width: 5px;"></div>
  <div>NumberOfRejections<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="NumberOfSubmissions" type="text" placeholder="Enter NumberOfSubmissions" v-model="jobDetails.numberOfSubmissions"></input></div>
  <div style="width: 5px;"></div>
  <div>NumberOfSubmissions<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="ClientId" type="text" placeholder="Enter ClientId" v-model="jobDetails.clientId"></input></div>
  <div style="width: 5px;"></div>
  <div>ClientId<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="ClientName" type="text" placeholder="Enter ClientName" v-model="jobDetails.clientName"></input></div>
  <div style="width: 5px;"></div>
  <div>ClientName<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="OpenedDate" type="text" placeholder="Enter OpenedDate" v-model="jobDetails.openedDate"></input></div>
  <div style="width: 5px;"></div>
  <div>OpenedDate<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="ClosedDate" type="text" placeholder="Enter ClosedDate" v-model="jobDetails.closedDate"></input></div>
  <div style="width: 5px;"></div>
  <div>ClosedDate<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="AddressId" type="text" placeholder="Enter AddressId" v-model="jobDetails.addressId"></input></div>
  <div style="width: 5px;"></div>
  <div>AddressId<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="ImageFile" type="text" placeholder="Enter ImageFile" v-model="jobDetails.imageFile"></input></div>
  <div style="width: 5px;"></div>
  <div>ImageFile<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="CityState" type="text" placeholder="Enter CityState" v-model="jobDetails.cityState"></input></div>
  <div style="width: 5px;"></div>
  <div>CityState<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="Active" type="text" placeholder="Enter Active" v-model="jobDetails.active"></input></div>
  <div style="width: 5px;"></div>
  <div>Active<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="Description" type="text" placeholder="Enter Description" v-model="jobDetails.description"></input></div>
  <div style="width: 5px;"></div>
  <div>Description<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="LastModified" type="text" placeholder="Enter LastModified" v-model="jobDetails.lastModified"></input></div>
  <div style="width: 5px;"></div>
  <div>LastModified<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="CreatedOn" type="text" placeholder="Enter CreatedOn" v-model="jobDetails.createdOn"></input></div>
  <div style="width: 5px;"></div>
  <div>CreatedOn<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="CreatedBy" type="text" placeholder="Enter CreatedBy" v-model="jobDetails.createdBy"></input></div>
  <div style="width: 5px;"></div>
  <div>CreatedBy<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="CreatedById" type="text" placeholder="Enter CreatedById" v-model="jobDetails.createdById"></input></div>
  <div style="width: 5px;"></div>
  <div>CreatedById<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="SensitivityLevel" type="text" placeholder="Enter SensitivityLevel" v-model="jobDetails.sensitivityLevel"></input></div>
  <div style="width: 5px;"></div>
  <div>SensitivityLevel<br><br></div>
</div>
<br>


  <div>
      <base-button class="btn btn-primary" @click="updateJob()">Save</base-button>
  </div>

</div>
</template>
<script>

import JobService from "../services/JobService";
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/Inputs/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/index";
import { ASelect, ASelectOption, AButton, Table, Pagination } from "ant-design-vue";
import { VueAutosuggest } from "vue-autosuggest";

export default {
  props: {
    jobId: {
      type: String,
      required: true
    }
  },
  components: {
		VueAutosuggest
  },
  data() {
    return {
      jobDetails: null,
    };
  },
  methods: {

    
    async updateJob() {

      const jsonData = JSON.stringify(this.jobDetails);
      const res = await JobService.update(jsonData);
	


      if (res.status === 200) {
//        this.$notify({
//          component: NotificationTemplate,
//          icon: "tim-icons icon-bell-55",
//          type: "success",
//          timeout: 3000,
//        });

//        this.modalJobs = false;
//        this.getAllJobs();
      }
    },

    async getJobDetails() {
      try {
	    let response = await JobService.get(this.jobId);
	    this.jobDetails = response.data;
      } catch (error) {
        console.error('Error fetching job details:', error);
      }
    }
  },
  mounted() {
    this.getJobDetails();
  },
  computed: {
  },
  
};
</script>
<style>
.input {
	text-align: center;
}
#autosuggest__input {
  display: inline-block;
}
.autosuggest__results-container {
	position: relative;
}
	.autosuggest__results {
	font-weight: 300;
	margin: 0;
	position: absolute;
	z-index: 10000001;
	border: 1px solid #e0e0e0;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	background: white;
}
	.autosuggest__results ul {
	list-style: none;
	padding-left: 0;
	margin: 0;
}
.autosuggest__results .autosuggest__results-item {
	cursor: pointer;
	padding: 5px;
}
#autosuggest ul:nth-child(1) > .autosuggest__results_title {
	border-top: none;
}
.autosuggest__results .autosuggest__results_title {
	color: gray;
	font-size: 11px;
	margin-left: 0;
	padding: 15px 13px 5px;
	border-top: 1px solid lightgray;
}
.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results
.autosuggest__results-item.autosuggest__results-item--highlighted {
	background-color: #F6F6F6;
}
</style>


