<template>
  <div class="content">


  <h5>{{ this.candidateId }}</h5>

<div  style="display: inline-flex;">
  <div><input label="CandidateId" type="text" placeholder="Enter CandidateId" v-model="candidateDetails.candidateId"></input></div>
  <div style="width: 5px;"></div>
  <div>CandidateId<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="CandidateNumber" type="text" placeholder="Enter CandidateNumber" v-model="candidateDetails.candidateNumber"></input></div>
  <div style="width: 5px;"></div>
  <div>CandidateNumber<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="CandidateName" type="text" placeholder="Enter CandidateName" v-model="candidateDetails.candidateName"></input></div>
  <div style="width: 5px;"></div>
  <div>CandidateName<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="CandidateType" type="text" placeholder="Enter CandidateType" v-model="candidateDetails.candidateType"></input></div>
  <div style="width: 5px;"></div>
  <div>CandidateType<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="LastPlaced" type="text" placeholder="Enter LastPlaced" v-model="candidateDetails.lastPlaced"></input></div>
  <div style="width: 5px;"></div>
  <div>LastPlaced<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="LastApplied" type="text" placeholder="Enter LastApplied" v-model="candidateDetails.lastApplied"></input></div>
  <div style="width: 5px;"></div>
  <div>LastApplied<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="Schools" type="text" placeholder="Enter Schools" v-model="candidateDetails.schools"></input></div>
  <div style="width: 5px;"></div>
  <div>Schools<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="Companies" type="text" placeholder="Enter Companies" v-model="candidateDetails.companies"></input></div>
  <div style="width: 5px;"></div>
  <div>Companies<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="Skills" type="text" placeholder="Enter Skills" v-model="candidateDetails.skills"></input></div>
  <div style="width: 5px;"></div>
  <div>Skills<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="Roles" type="text" placeholder="Enter Roles" v-model="candidateDetails.roles"></input></div>
  <div style="width: 5px;"></div>
  <div>Roles<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="FirstName" type="text" placeholder="Enter FirstName" v-model="candidateDetails.firstName"></input></div>
  <div style="width: 5px;"></div>
  <div>FirstName<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="LastName" type="text" placeholder="Enter LastName" v-model="candidateDetails.lastName"></input></div>
  <div style="width: 5px;"></div>
  <div>LastName<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="MiddleName" type="text" placeholder="Enter MiddleName" v-model="candidateDetails.middleName"></input></div>
  <div style="width: 5px;"></div>
  <div>MiddleName<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="PreferredName" type="text" placeholder="Enter PreferredName" v-model="candidateDetails.preferredName"></input></div>
  <div style="width: 5px;"></div>
  <div>PreferredName<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="Birthday" type="text" placeholder="Enter Birthday" v-model="candidateDetails.birthday"></input></div>
  <div style="width: 5px;"></div>
  <div>Birthday<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="AddressId" type="text" placeholder="Enter AddressId" v-model="candidateDetails.addressId"></input></div>
  <div style="width: 5px;"></div>
  <div>AddressId<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="ImageFile" type="text" placeholder="Enter ImageFile" v-model="candidateDetails.imageFile"></input></div>
  <div style="width: 5px;"></div>
  <div>ImageFile<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="CityState" type="text" placeholder="Enter CityState" v-model="candidateDetails.cityState"></input></div>
  <div style="width: 5px;"></div>
  <div>CityState<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="Active" type="text" placeholder="Enter Active" v-model="candidateDetails.active"></input></div>
  <div style="width: 5px;"></div>
  <div>Active<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="Description" type="text" placeholder="Enter Description" v-model="candidateDetails.description"></input></div>
  <div style="width: 5px;"></div>
  <div>Description<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="LastModified" type="text" placeholder="Enter LastModified" v-model="candidateDetails.lastModified"></input></div>
  <div style="width: 5px;"></div>
  <div>LastModified<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="CreatedOn" type="text" placeholder="Enter CreatedOn" v-model="candidateDetails.createdOn"></input></div>
  <div style="width: 5px;"></div>
  <div>CreatedOn<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="CreatedBy" type="text" placeholder="Enter CreatedBy" v-model="candidateDetails.createdBy"></input></div>
  <div style="width: 5px;"></div>
  <div>CreatedBy<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="CreatedById" type="text" placeholder="Enter CreatedById" v-model="candidateDetails.createdById"></input></div>
  <div style="width: 5px;"></div>
  <div>CreatedById<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="SensitivityLevel" type="text" placeholder="Enter SensitivityLevel" v-model="candidateDetails.sensitivityLevel"></input></div>
  <div style="width: 5px;"></div>
  <div>SensitivityLevel<br><br></div>
</div>
<br>


  <div>
      <base-button class="btn btn-primary" @click="updateCandidate()">Save</base-button>
  </div>

</div>
</template>
<script>

import CandidateService from "../services/CandidateService";
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/Inputs/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/index";
import { ASelect, ASelectOption, AButton, Table, Pagination } from "ant-design-vue";
import { VueAutosuggest } from "vue-autosuggest";

export default {
  props: {
    candidateId: {
      type: String,
      required: true
    }
  },
  components: {
		VueAutosuggest
  },
  data() {
    return {
      candidateDetails: null,
    };
  },
  methods: {

    
    async updateCandidate() {

      const jsonData = JSON.stringify(this.candidateDetails);
      const res = await CandidateService.update(jsonData);
	


      if (res.status === 200) {
//        this.$notify({
//          component: NotificationTemplate,
//          icon: "tim-icons icon-bell-55",
//          type: "success",
//          timeout: 3000,
//        });

//        this.modalCandidates = false;
//        this.getAllCandidates();
      }
    },

    async getCandidateDetails() {
      try {
	    let response = await CandidateService.get(this.candidateId);
	    this.candidateDetails = response.data;
      } catch (error) {
        console.error('Error fetching candidate details:', error);
      }
    }
  },
  mounted() {
    this.getCandidateDetails();
  },
  computed: {
  },
  
};
</script>
<style>
.input {
	text-align: center;
}
#autosuggest__input {
  display: inline-block;
}
.autosuggest__results-container {
	position: relative;
}
	.autosuggest__results {
	font-weight: 300;
	margin: 0;
	position: absolute;
	z-index: 10000001;
	border: 1px solid #e0e0e0;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	background: white;
}
	.autosuggest__results ul {
	list-style: none;
	padding-left: 0;
	margin: 0;
}
.autosuggest__results .autosuggest__results-item {
	cursor: pointer;
	padding: 5px;
}
#autosuggest ul:nth-child(1) > .autosuggest__results_title {
	border-top: none;
}
.autosuggest__results .autosuggest__results_title {
	color: gray;
	font-size: 11px;
	margin-left: 0;
	padding: 15px 13px 5px;
	border-top: 1px solid lightgray;
}
.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results
.autosuggest__results-item.autosuggest__results-item--highlighted {
	background-color: #F6F6F6;
}
</style>


