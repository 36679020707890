<template>
    <div>
        <div class="row card-container">
            <div class="col match-card" v-for="match in matches" :key="match.matchId">
                <img class="match-image" :alt="match.matchNumber" :src="`/images/${match.imageFile}`" />
                <!-- <img class="match-image" :alt="match.matchNumber"
                    :src="`https://source.unsplash.com/collection/928423/230x250`" /> -->
                <div class="match-info">
                    <div class="match-name">{{ match.matchNumber }}</div>
                    <div class="match-details">
                        <p>{{ match.matchNumber }}</p>
                        <p>{{ match.locationNumber }}</p>
                        <p>{{ match.operatorNumber }}</p>
                        <a :href="match.matchNumber" target="_blank" class="match-link">match Webpage</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>

export default {
    components: {
    },
    props: {
        matches: Array,
    },

    methods: {

    },
};
</script>
  
<style scoped>
.card-container {
    display: flex;
    column-gap: 15px;
    flex-wrap: wrap;
    margin: 5px;

}

.card {
    max-width: 230px;

}

.match-picture-view {
    margin-bottom: 15px;
    /* Separate each row from the content below */
}

.match-card {
    max-width: 250px;
    min-width: 250px;
    border-radius: 4px;
    padding: 0px;
    margin-bottom: 25px;
    /* Separate each card */
}

.match-image {
    width: 250px;
    height: 200px;
    /* object-fit: contain; */
    border-radius: 4px;
}

.match-info {
    margin-top: 0px;
}

.match-name {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 2px;
    line-height: 1.2;
}

.match-details p {
    margin-top: 0px;
    text-align: left;
}

.match-link {
    display: inline;
    margin-top: 5px;
    color: #1890ff;
}
</style>
  