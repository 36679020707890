import axios from "axios";

export default axios.create({
//  baseURL: "http://107.173.210.120:8080/",
  baseURL: "https://drc-stealth.rocks:8080/",
  headers: {
    "Content-type": "application/json",
  },
});


