<template>
    <div>
        <div class="row card-container">
            <div class="col submission-card" v-for="submission in submissions" :key="submission.submissionId">
                <img class="submission-image" :alt="submission.submissionNumber" :src="`/images/${submission.imageFile}`" />
                <!-- <img class="submission-image" :alt="submission.submissionNumber"
                    :src="`https://source.unsplash.com/collection/928423/230x250`" /> -->
                <div class="submission-info">
                    <div class="submission-name">{{ submission.submissionNumber }}</div>
                    <div class="submission-details">
                        <p>{{ submission.submissionNumber }}</p>
                        <p>{{ submission.locationNumber }}</p>
                        <p>{{ submission.operatorNumber }}</p>
                        <a :href="submission.submissionNumber" target="_blank" class="submission-link">submission Webpage</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>

export default {
    components: {
    },
    props: {
        submissions: Array,
    },

    methods: {

    },
};
</script>
  
<style scoped>
.card-container {
    display: flex;
    column-gap: 15px;
    flex-wrap: wrap;
    margin: 5px;

}

.card {
    max-width: 230px;

}

.submission-picture-view {
    margin-bottom: 15px;
    /* Separate each row from the content below */
}

.submission-card {
    max-width: 250px;
    min-width: 250px;
    border-radius: 4px;
    padding: 0px;
    margin-bottom: 25px;
    /* Separate each card */
}

.submission-image {
    width: 250px;
    height: 200px;
    /* object-fit: contain; */
    border-radius: 4px;
}

.submission-info {
    margin-top: 0px;
}

.submission-name {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 2px;
    line-height: 1.2;
}

.submission-details p {
    margin-top: 0px;
    text-align: left;
}

.submission-link {
    display: inline;
    margin-top: 5px;
    color: #1890ff;
}
</style>
  