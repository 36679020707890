<template>
  <div class="content">
    <!-- search bar -->
    <div class="row my-3">
      <div class="col-8"></div>
      <div class="col-4">
        <!-- Header Search Input -->
        <a-input-search class="header-search" :class="searchLoading ? 'loading' : ''" placeholder="Search by BusinessUnit#, Location#, Operator#, City, State, FirstName, LastName…"
          @search="onSearch" :loading='searchLoading' v-model="searchQuery">
          <svg slot="prefix" width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.29583 13.5892 10.4957 12.8907 11.4765L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L11.4765 12.8907C10.4957 13.5892 9.29583 14 8 14C4.68629 14 2 11.3137 2 8Z"
              fill="#111827" />
          </svg>
        </a-input-search>
        <!-- / Header Search Input -->
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card>
          <template slot="header">
            <div class="row justify-content-between align-items-between mx-3">

              <h4 class="card-title">Clients</h4>
              
              <div>
                  <base-button class="btn btn-primary" @click="modalClients = true">Add</base-button>
              </div>
              
              <modal :show.sync="modalClients">
                <template slot="header">
                  <h5 class="modal-title" id="exampleModalLabel">Add Client</h5>
                </template>
                <div>
                  <form @submit.prevent>
  <base-input label="ClientId" type="text" placeholder="Enter ClientId" v-model="clientsData.clientId"></base-input>
  <base-input label="ClientNumber" type="text" placeholder="Enter ClientNumber" v-model="clientsData.clientNumber"></base-input>
  <base-input label="ClientName" type="text" placeholder="Enter ClientName" v-model="clientsData.clientName"></base-input>
  <base-input label="ClientType" type="text" placeholder="Enter ClientType" v-model="clientsData.clientType"></base-input>
  <base-input label="ContactName" type="text" placeholder="Enter ContactName" v-model="clientsData.contactName"></base-input>
  <base-input label="NumberOfOpenJobs" type="text" placeholder="Enter NumberOfOpenJobs" v-model="clientsData.numberOfOpenJobs"></base-input>
  <base-input label="NumberOfClosedJobs" type="text" placeholder="Enter NumberOfClosedJobs" v-model="clientsData.numberOfClosedJobs"></base-input>
  <base-input label="NumberOfPlacements" type="text" placeholder="Enter NumberOfPlacements" v-model="clientsData.numberOfPlacements"></base-input>
  <base-input label="NumberOfApplicants" type="text" placeholder="Enter NumberOfApplicants" v-model="clientsData.numberOfApplicants"></base-input>
  <base-input label="NumberOfRejections" type="text" placeholder="Enter NumberOfRejections" v-model="clientsData.numberOfRejections"></base-input>
  <base-input label="NumberOfSubmissions" type="text" placeholder="Enter NumberOfSubmissions" v-model="clientsData.numberOfSubmissions"></base-input>
  <base-input label="AddressId" type="text" placeholder="Enter AddressId" v-model="clientsData.addressId"></base-input>
  <base-input label="ImageFile" type="text" placeholder="Enter ImageFile" v-model="clientsData.imageFile"></base-input>
  <base-input label="CityState" type="text" placeholder="Enter CityState" v-model="clientsData.cityState"></base-input>
  <base-input label="Active" type="text" placeholder="Enter Active" v-model="clientsData.active"></base-input>
  <base-input label="Description" type="text" placeholder="Enter Description" v-model="clientsData.description"></base-input>
  <base-input label="LastModified" type="text" placeholder="Enter LastModified" v-model="clientsData.lastModified"></base-input>
  <base-input label="CreatedOn" type="text" placeholder="Enter CreatedOn" v-model="clientsData.createdOn"></base-input>
  <base-input label="CreatedBy" type="text" placeholder="Enter CreatedBy" v-model="clientsData.createdBy"></base-input>
  <base-input label="CreatedById" type="text" placeholder="Enter CreatedById" v-model="clientsData.createdById"></base-input>
  <base-input label="SensitivityLevel" type="text" placeholder="Enter SensitivityLevel" v-model="clientsData.sensitivityLevel"></base-input>
                  </form>
                </div>
                <template slot="footer">
                  <base-button type="primary" @click="submitClients()">Save</base-button>
                </template>
              </modal>
            </div>
          </template>


          <!-- Conditionally render the view based on the 'isTableView' flag -->
          <div v-if="isTableView">
            <!-- Render the existing Table View -->
            <a-table :columns="columns" :data-source="clients" :row-key="record => record.ClientId" :pagination="pagination"
              :loading="searchLoading" @change="onTableChange" :scroll="{ x: 'max-content' }">
            </a-table>
          </div>
          <div v-else>
            <!-- Render the Picture View  -->
            <ClientPictureView :clients="clients" />
          </div>

        </card>
      </div>
    </div>

  </div>
</template>

<script>
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/Inputs/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/index";
import ClientService from "../services/ClientService";
import { ASelect, ASelectOption, AButton, Table, Pagination } from "ant-design-vue";
import ClientPictureView from './ClientPictureView.vue';


const clientsColumns = [
  "clientId",
  "year",
  "date",
  "competitionId",
  "clientId"
]

export default {
  components: {
    Card,
    Modal,
    BaseButton,
    BaseInput,
    Table,

    Pagination,
    ClientPictureView
  },

  data() {
    return {
      modalClients: false,
        isTableView: true,

      columns: [
        {
          title: 'ClientId',
          dataIndex: 'clientId',
          visible: false,
          //scopedSlots: { customRender: 'clientId' },
          //sorter: (a, b) => a.clientId - b.clientId,
          //sorter: (a, b) => a.clientId.localeCompare(b.clientId),
        },
        {
          title: 'ClientNumber',
          dataIndex: 'clientNumber',
          visible: true,
          //scopedSlots: { customRender: 'clientNumber' },
          //sorter: (a, b) => a.clientNumber - b.clientNumber,
          //sorter: (a, b) => a.clientNumber.localeCompare(b.clientNumber),
        },
        {
          title: 'ClientName',
          dataIndex: 'clientName',
          visible: true,
          //scopedSlots: { customRender: 'clientName' },
          //sorter: (a, b) => a.clientName - b.clientName,
          //sorter: (a, b) => a.clientName.localeCompare(b.clientName),
        },
        {
          title: 'ClientType',
          dataIndex: 'clientType',
          visible: true,
          //scopedSlots: { customRender: 'clientType' },
          //sorter: (a, b) => a.clientType - b.clientType,
          //sorter: (a, b) => a.clientType.localeCompare(b.clientType),
        },
        {
          title: 'ContactName',
          dataIndex: 'contactName',
          visible: true,
          //scopedSlots: { customRender: 'contactName' },
          //sorter: (a, b) => a.contactName - b.contactName,
          //sorter: (a, b) => a.contactName.localeCompare(b.contactName),
        },
        {
          title: 'NumberOfOpenJobs',
          dataIndex: 'numberOfOpenJobs',
          visible: false,
          //scopedSlots: { customRender: 'numberOfOpenJobs' },
          //sorter: (a, b) => a.numberOfOpenJobs - b.numberOfOpenJobs,
          //sorter: (a, b) => a.numberOfOpenJobs.localeCompare(b.numberOfOpenJobs),
        },
        {
          title: 'NumberOfClosedJobs',
          dataIndex: 'numberOfClosedJobs',
          visible: false,
          //scopedSlots: { customRender: 'numberOfClosedJobs' },
          //sorter: (a, b) => a.numberOfClosedJobs - b.numberOfClosedJobs,
          //sorter: (a, b) => a.numberOfClosedJobs.localeCompare(b.numberOfClosedJobs),
        },
        {
          title: 'NumberOfPlacements',
          dataIndex: 'numberOfPlacements',
          visible: false,
          //scopedSlots: { customRender: 'numberOfPlacements' },
          //sorter: (a, b) => a.numberOfPlacements - b.numberOfPlacements,
          //sorter: (a, b) => a.numberOfPlacements.localeCompare(b.numberOfPlacements),
        },
        {
          title: 'NumberOfApplicants',
          dataIndex: 'numberOfApplicants',
          visible: false,
          //scopedSlots: { customRender: 'numberOfApplicants' },
          //sorter: (a, b) => a.numberOfApplicants - b.numberOfApplicants,
          //sorter: (a, b) => a.numberOfApplicants.localeCompare(b.numberOfApplicants),
        },
        {
          title: 'NumberOfRejections',
          dataIndex: 'numberOfRejections',
          visible: false,
          //scopedSlots: { customRender: 'numberOfRejections' },
          //sorter: (a, b) => a.numberOfRejections - b.numberOfRejections,
          //sorter: (a, b) => a.numberOfRejections.localeCompare(b.numberOfRejections),
        },
        {
          title: 'NumberOfSubmissions',
          dataIndex: 'numberOfSubmissions',
          visible: false,
          //scopedSlots: { customRender: 'numberOfSubmissions' },
          //sorter: (a, b) => a.numberOfSubmissions - b.numberOfSubmissions,
          //sorter: (a, b) => a.numberOfSubmissions.localeCompare(b.numberOfSubmissions),
        },
        {
          title: 'AddressId',
          dataIndex: 'addressId',
          visible: false,
          //scopedSlots: { customRender: 'addressId' },
          //sorter: (a, b) => a.addressId - b.addressId,
          //sorter: (a, b) => a.addressId.localeCompare(b.addressId),
        },
        {
          title: 'ImageFile',
          dataIndex: 'imageFile',
          visible: false,
          //scopedSlots: { customRender: 'imageFile' },
          //sorter: (a, b) => a.imageFile - b.imageFile,
          //sorter: (a, b) => a.imageFile.localeCompare(b.imageFile),
        },
        {
          title: 'CityState',
          dataIndex: 'cityState',
          visible: true,
          //scopedSlots: { customRender: 'cityState' },
          //sorter: (a, b) => a.cityState - b.cityState,
          //sorter: (a, b) => a.cityState.localeCompare(b.cityState),
        },
        {
          title: 'Active',
          dataIndex: 'active',
          visible: false,
          //scopedSlots: { customRender: 'active' },
          //sorter: (a, b) => a.active - b.active,
          //sorter: (a, b) => a.active.localeCompare(b.active),
        },
        {
          title: 'Description',
          dataIndex: 'description',
          visible: false,
          //scopedSlots: { customRender: 'description' },
          //sorter: (a, b) => a.description - b.description,
          //sorter: (a, b) => a.description.localeCompare(b.description),
        },
        {
          title: 'LastModified',
          dataIndex: 'lastModified',
          visible: false,
          //scopedSlots: { customRender: 'lastModified' },
          //sorter: (a, b) => a.lastModified - b.lastModified,
          //sorter: (a, b) => a.lastModified.localeCompare(b.lastModified),
        },
        {
          title: 'CreatedOn',
          dataIndex: 'createdOn',
          visible: false,
          //scopedSlots: { customRender: 'createdOn' },
          //sorter: (a, b) => a.createdOn - b.createdOn,
          //sorter: (a, b) => a.createdOn.localeCompare(b.createdOn),
        },
        {
          title: 'CreatedBy',
          dataIndex: 'createdBy',
          visible: false,
          //scopedSlots: { customRender: 'createdBy' },
          //sorter: (a, b) => a.createdBy - b.createdBy,
          //sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
        },
        {
          title: 'CreatedById',
          dataIndex: 'createdById',
          visible: false,
          //scopedSlots: { customRender: 'createdById' },
          //sorter: (a, b) => a.createdById - b.createdById,
          //sorter: (a, b) => a.createdById.localeCompare(b.createdById),
        },
        {
          title: 'SensitivityLevel',
          dataIndex: 'sensitivityLevel',
          visible: false,
          //scopedSlots: { customRender: 'sensitivityLevel' },
          //sorter: (a, b) => a.sensitivityLevel - b.sensitivityLevel,
          //sorter: (a, b) => a.sensitivityLevel.localeCompare(b.sensitivityLevel),
        },
      ],
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total) => `Total ${total} clients`,
      },

      clientsData: {
        clientId: null,
        year: '',
        date: '',
        competitionId: '',
        clientId: ''
      },

      clients: [],


      clientsTable: {
        columns: [...clientsColumns],
        data: [],
      },

      // New properties for sorting and searching
      sortBy: 'clientId',           // Column to sort by
      sortOrder: 'asc',     // Sort order (asc or desc)
      searchQuery: '',      // Search query
      searchLoading: false, // Initialize searchLoading property


    };
  },
  watch: {
    searchQuery: {
      handler: "getAllClients", // Call the fetchData method when searchQuery changes
      immediate: true, // Trigger immediately when the component is mounted
    },
  },

  methods: {

    async submitClients() {
      const currentDate = new Date().getTime();
      this.clientsData.created = currentDate;

      const jsonData = JSON.stringify(this.clientsData);
      const res = await ClientService.addClient(jsonData);

      if (res.status === 200) {
        this.$notify({
          component: NotificationTemplate,
          icon: "tim-icons icon-bell-55",
          type: "success",
          timeout: 3000,
        });

        this.modalClients = false;
        this.getAllClients();
      }
    },

    async getAllClients() {
      this.searchLoading = true; // Set searchLoading to true while fetching data

      const response = await ClientService.getAllClients(this.sortBy, this.sortOrder, this.searchQuery, this.pagination.current,
        this.pagination.pageSize
      );
      this.clients = response.data.content;
      this.pagination.total = response.data.totalElements;
      this.searchLoading = false;

      console.log(this.clients);
      let clientsTableData = [];
      for (let i = 0; i < this.clients.length; i++) {
        clientsTableData.push({
          id: i,
          clientId: this.clients[i].clientId,
          year: this.clients[i].year,
          date: this.clients[i].date,
          competitionId: this.clients[i].competitionId,
          clientId: this.clients[i].clientId,
        });

      }
      this.searchLoading = false;


    },
    async onTableChange(pagination, filters, sorter) {
      if (sorter && sorter.field && sorter.order) {
        this.sortBy = sorter.field;
        if (sorter.order == "ascend") {
            this.sortOrder = "asc";
        } else {
            this.sortOrder = "desc";
        }
      }
      if (pagination) {
        this.pagination.current = pagination.current;
        this.pagination.pageSize = pagination.pageSize;
      }

      await this.getAllClients();
    },

    onSearch(value) {
      console.log(value);
      this.searchQuery = value; // Update searchQuery when the user types
    },

    toggleView() {
      this.isTableView = !this.isTableView;
    },
  },

  mounted() {
    this.getAllClients();
  }
};
</script>

<style>
.modal-dialog {
  margin-top: -300px;
}
</style>
