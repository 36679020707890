<template>
  <div class="content">
    <div class="row">
      <!-- <div class="col-12">
        <card>
          <template slot="header">
            <h4 class="card-title">Simple Table</h4>
          </template>
          <div class="table-responsive text-left">
            <match-table
              :data="table1.data"
              :columns="table1.columns"
              thead-classes="text-primary"
            >
            </match-table>
          </div>
        </card>
      </div> -->

      <div class="col-12">
        <card class="card-plain">
          <!-- <template slot="header">
            <h4 class="card-title">Table on Plain Background</h4>
            <p class="category">Here is a subtitle for this table</p>
          </template>-->
          <div class="table-full-width table-responsive text-left">
            <match-table
              :title="table2.title"
              :sub-title="table2.subTitle"
              :data="table2.data"
              :columns="table2.columns"
            >
            </match-table>
          </div>
        </card>
      </div>

    </div>
  </div>
</template>
<script>
import { Card } from "@/components/index";

import MatchTable from "@/components/MatchTable";

const tableColumns = ["Name", "Country", "City", "Salary"];
const tableData = [
];

export default {
  components: {
    Card,
    MatchTable,
  },
  data() {
    return {
      table1: {
        title: "Simple Table",
        columns: [...tableColumns],
        data: [...tableData],
      },
      table2: {
        title: "Table on Plain Background",
        columns: [...tableColumns],
        data: [...tableData],
      },
    };
  },
};
</script>
<style></style>