<template>
  <div class="content">


  <h5>{{ this.matchId }}</h5>

<div  style="display: inline-flex;">
  <div><input label="MatchId" type="text" placeholder="Enter MatchId" v-model="matchDetails.matchId"></input></div>
  <div style="width: 5px;"></div>
  <div>MatchId<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="ClientName" type="text" placeholder="Enter ClientName" v-model="matchDetails.clientName"></input></div>
  <div style="width: 5px;"></div>
  <div>ClientName<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="JobName" type="text" placeholder="Enter JobName" v-model="matchDetails.jobName"></input></div>
  <div style="width: 5px;"></div>
  <div>JobName<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="CandidateName" type="text" placeholder="Enter CandidateName" v-model="matchDetails.candidateName"></input></div>
  <div style="width: 5px;"></div>
  <div>CandidateName<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="MatchScore" type="text" placeholder="Enter MatchScore" v-model="matchDetails.matchScore"></input></div>
  <div style="width: 5px;"></div>
  <div>MatchScore<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="ScorePriorRoles" type="text" placeholder="Enter ScorePriorRoles" v-model="matchDetails.scorePriorRoles"></input></div>
  <div style="width: 5px;"></div>
  <div>ScorePriorRoles<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="ScoreAvailability" type="text" placeholder="Enter ScoreAvailability" v-model="matchDetails.scoreAvailability"></input></div>
  <div style="width: 5px;"></div>
  <div>ScoreAvailability<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="ScoreLocation" type="text" placeholder="Enter ScoreLocation" v-model="matchDetails.scoreLocation"></input></div>
  <div style="width: 5px;"></div>
  <div>ScoreLocation<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="ScoreSchools" type="text" placeholder="Enter ScoreSchools" v-model="matchDetails.scoreSchools"></input></div>
  <div style="width: 5px;"></div>
  <div>ScoreSchools<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="ScoreSkills" type="text" placeholder="Enter ScoreSkills" v-model="matchDetails.scoreSkills"></input></div>
  <div style="width: 5px;"></div>
  <div>ScoreSkills<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="ScoreEducationLevel" type="text" placeholder="Enter ScoreEducationLevel" v-model="matchDetails.scoreEducationLevel"></input></div>
  <div style="width: 5px;"></div>
  <div>ScoreEducationLevel<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="Active" type="text" placeholder="Enter Active" v-model="matchDetails.active"></input></div>
  <div style="width: 5px;"></div>
  <div>Active<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="Description" type="text" placeholder="Enter Description" v-model="matchDetails.description"></input></div>
  <div style="width: 5px;"></div>
  <div>Description<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="LastModified" type="text" placeholder="Enter LastModified" v-model="matchDetails.lastModified"></input></div>
  <div style="width: 5px;"></div>
  <div>LastModified<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="CreatedOn" type="text" placeholder="Enter CreatedOn" v-model="matchDetails.createdOn"></input></div>
  <div style="width: 5px;"></div>
  <div>CreatedOn<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="CreatedBy" type="text" placeholder="Enter CreatedBy" v-model="matchDetails.createdBy"></input></div>
  <div style="width: 5px;"></div>
  <div>CreatedBy<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="CreatedById" type="text" placeholder="Enter CreatedById" v-model="matchDetails.createdById"></input></div>
  <div style="width: 5px;"></div>
  <div>CreatedById<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="SensitivityLevel" type="text" placeholder="Enter SensitivityLevel" v-model="matchDetails.sensitivityLevel"></input></div>
  <div style="width: 5px;"></div>
  <div>SensitivityLevel<br><br></div>
</div>
<br>

<div  style="display: inline-flex;">
  <div>
	  <vue-autosuggest label="JobId" type="text" placeholder="Enter JobId" v-model="matchDetails.jobId" :suggestions="filteredSuggestionsJobId" :input-props="{id:'autosuggest__inputJobId'}" @selected="selectHandlerJobId" @input="onInputChangeJobId" :getSuggestionValue="s => s.item.jobId.toString()">
	    <template slot-scope="{ suggestion }">
	      <div>
	        <b>{{suggestion.item.jobName}}</b>
	      </div>
	    </template>
	  </vue-autosuggest>
  </div>
  <div style="width: 5px;"></div>
  <div>JobId<br><br></div>
</div>  
<br>
<div  style="display: inline-flex;">
  <div>
	  <vue-autosuggest label="CandidateId" type="text" placeholder="Enter CandidateId" v-model="matchDetails.candidateId" :suggestions="filteredSuggestionsCandidateId" :input-props="{id:'autosuggest__inputCandidateId'}" @selected="selectHandlerCandidateId" @input="onInputChangeCandidateId" :getSuggestionValue="s => s.item.candidateId.toString()">
	    <template slot-scope="{ suggestion }">
	      <div>
	        <b>{{suggestion.item.candidateName}}</b>
	      </div>
	    </template>
	  </vue-autosuggest>
  </div>
  <div style="width: 5px;"></div>
  <div>CandidateId<br><br></div>
</div>  
<br>

  <div>
      <base-button class="btn btn-primary" @click="updateMatch()">Save</base-button>
  </div>

</div>
</template>
<script>

import MatchService from "../services/MatchService";
import JobService from "../services/JobService";
import CandidateService from "../services/CandidateService";
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/Inputs/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/index";
import { ASelect, ASelectOption, AButton, Table, Pagination } from "ant-design-vue";
import { VueAutosuggest } from "vue-autosuggest";

export default {
  props: {
    matchId: {
      type: String,
      required: true
    }
  },
  components: {
		VueAutosuggest
  },
  data() {
    return {
      matchDetails: null,

      jobNameQuery: "",
      suggestionsJobId: [],
      suggestionsLoadedJobId: false,

      candidateNameQuery: "",
      suggestionsCandidateId: [],
      suggestionsLoadedCandidateId: false,
    };
  },
  methods: {

    
    selectHandlerJobId(item) {
      this.matchDetails.jobId = item.item.jobId.toString();
    },

    async getJobNameSuggestions()
    {
      try {
        let response = await JobService.getAllJobs("jobId","asc",this.matchDetails.jobId,"1","15");
        this.suggestionsJobId = response.data.content;
        this.suggestionsLoadedJobId = true;
      } catch (error) {
        console.error('Error fetching jobs list for auto-complete: ', error);
      }
    },
    
	onInputChangeJobId(text) {
      this.getJobNameSuggestions();
    },
    selectHandlerCandidateId(item) {
      this.matchDetails.candidateId = item.item.candidateId.toString();
    },

    async getCandidateNameSuggestions()
    {
      try {
        let response = await CandidateService.getAllCandidates("candidateId","asc",this.matchDetails.candidateId,"1","15");
        this.suggestionsCandidateId = response.data.content;
        this.suggestionsLoadedCandidateId = true;
      } catch (error) {
        console.error('Error fetching candidates list for auto-complete: ', error);
      }
    },
    
	onInputChangeCandidateId(text) {
      this.getCandidateNameSuggestions();
    },
    async updateMatch() {

      const jsonData = JSON.stringify(this.matchDetails);
      const res = await MatchService.update(jsonData);
	


      if (res.status === 200) {
//        this.$notify({
//          component: NotificationTemplate,
//          icon: "tim-icons icon-bell-55",
//          type: "success",
//          timeout: 3000,
//        });

//        this.modalMatches = false;
//        this.getAllMatches();
      }
    },

    async getMatchDetails() {
      try {
	    let response = await MatchService.get(this.matchId);
	    this.matchDetails = response.data;
      } catch (error) {
        console.error('Error fetching match details:', error);
      }
    }
  },
  mounted() {
    this.getMatchDetails();
	this.getJobNameSuggestions();
	this.getCandidateNameSuggestions();
  },
  computed: {
	  filteredSuggestionsJobId() {
	    if (!this.suggestionsLoadedJobId)
	      return [];
	    this.jobNameQuery = this.matchDetails.jobId.toString();
	    return [
	      {
	        data: this.suggestionsJobId
	      }
	    ];
	  },
	  filteredSuggestionsCandidateId() {
	    if (!this.suggestionsLoadedCandidateId)
	      return [];
	    this.candidateNameQuery = this.matchDetails.candidateId.toString();
	    return [
	      {
	        data: this.suggestionsCandidateId
	      }
	    ];
	  },
  },
  
};
</script>
<style>
.input {
	text-align: center;
}
#autosuggest__input {
  display: inline-block;
}
.autosuggest__results-container {
	position: relative;
}
	.autosuggest__results {
	font-weight: 300;
	margin: 0;
	position: absolute;
	z-index: 10000001;
	border: 1px solid #e0e0e0;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	background: white;
}
	.autosuggest__results ul {
	list-style: none;
	padding-left: 0;
	margin: 0;
}
.autosuggest__results .autosuggest__results-item {
	cursor: pointer;
	padding: 5px;
}
#autosuggest ul:nth-child(1) > .autosuggest__results_title {
	border-top: none;
}
.autosuggest__results .autosuggest__results_title {
	color: gray;
	font-size: 11px;
	margin-left: 0;
	padding: 15px 13px 5px;
	border-top: 1px solid lightgray;
}
.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results
.autosuggest__results-item.autosuggest__results-item--highlighted {
	background-color: #F6F6F6;
}
</style>


