<template>
  <div class="content">
    <!-- search bar -->
    <div class="row my-3">
      <div class="col-8"></div>
      <div class="col-4">
        <!-- Header Search Input -->
        <a-input-search class="header-search" :class="searchLoading ? 'loading' : ''" placeholder="Search by BusinessUnit#, Location#, Operator#, City, State, FirstName, LastName…"
          @search="onSearch" :loading='searchLoading' v-model="searchQuery">
          <svg slot="prefix" width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.29583 13.5892 10.4957 12.8907 11.4765L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L11.4765 12.8907C10.4957 13.5892 9.29583 14 8 14C4.68629 14 2 11.3137 2 8Z"
              fill="#111827" />
          </svg>
        </a-input-search>
        <!-- / Header Search Input -->
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card>
          <template slot="header">
            <div class="row justify-content-between align-items-between mx-3">

              <h4 class="card-title">Jobs</h4>
              
              <div>
                  <base-button class="btn btn-primary" @click="modalJobs = true">Add</base-button>
              </div>
              
              <modal :show.sync="modalJobs">
                <template slot="header">
                  <h5 class="modal-title" id="exampleModalLabel">Add Job</h5>
                </template>
                <div>
                  <form @submit.prevent>
  <base-input label="JobId" type="text" placeholder="Enter JobId" v-model="jobsData.jobId"></base-input>
  <base-input label="JobNumber" type="text" placeholder="Enter JobNumber" v-model="jobsData.jobNumber"></base-input>
  <base-input label="JobName" type="text" placeholder="Enter JobName" v-model="jobsData.jobName"></base-input>
  <base-input label="JobType" type="text" placeholder="Enter JobType" v-model="jobsData.jobType"></base-input>
  <base-input label="Role" type="text" placeholder="Enter Role" v-model="jobsData.role"></base-input>
  <base-input label="Company" type="text" placeholder="Enter Company" v-model="jobsData.company"></base-input>
  <base-input label="IsContract" type="text" placeholder="Enter IsContract" v-model="jobsData.isContract"></base-input>
  <base-input label="IsFte" type="text" placeholder="Enter IsFte" v-model="jobsData.isFte"></base-input>
  <base-input label="IsFullTime" type="text" placeholder="Enter IsFullTime" v-model="jobsData.isFullTime"></base-input>
  <base-input label="IsPartTime" type="text" placeholder="Enter IsPartTime" v-model="jobsData.isPartTime"></base-input>
  <base-input label="NumberOfApplicants" type="text" placeholder="Enter NumberOfApplicants" v-model="jobsData.numberOfApplicants"></base-input>
  <base-input label="NumberOfRejections" type="text" placeholder="Enter NumberOfRejections" v-model="jobsData.numberOfRejections"></base-input>
  <base-input label="NumberOfSubmissions" type="text" placeholder="Enter NumberOfSubmissions" v-model="jobsData.numberOfSubmissions"></base-input>
  <base-input label="ClientId" type="text" placeholder="Enter ClientId" v-model="jobsData.clientId"></base-input>
  <base-input label="ClientName" type="text" placeholder="Enter ClientName" v-model="jobsData.clientName"></base-input>
  <base-input label="OpenedDate" type="text" placeholder="Enter OpenedDate" v-model="jobsData.openedDate"></base-input>
  <base-input label="ClosedDate" type="text" placeholder="Enter ClosedDate" v-model="jobsData.closedDate"></base-input>
  <base-input label="AddressId" type="text" placeholder="Enter AddressId" v-model="jobsData.addressId"></base-input>
  <base-input label="ImageFile" type="text" placeholder="Enter ImageFile" v-model="jobsData.imageFile"></base-input>
  <base-input label="CityState" type="text" placeholder="Enter CityState" v-model="jobsData.cityState"></base-input>
  <base-input label="Active" type="text" placeholder="Enter Active" v-model="jobsData.active"></base-input>
  <base-input label="Description" type="text" placeholder="Enter Description" v-model="jobsData.description"></base-input>
  <base-input label="LastModified" type="text" placeholder="Enter LastModified" v-model="jobsData.lastModified"></base-input>
  <base-input label="CreatedOn" type="text" placeholder="Enter CreatedOn" v-model="jobsData.createdOn"></base-input>
  <base-input label="CreatedBy" type="text" placeholder="Enter CreatedBy" v-model="jobsData.createdBy"></base-input>
  <base-input label="CreatedById" type="text" placeholder="Enter CreatedById" v-model="jobsData.createdById"></base-input>
  <base-input label="SensitivityLevel" type="text" placeholder="Enter SensitivityLevel" v-model="jobsData.sensitivityLevel"></base-input>
                  </form>
                </div>
                <template slot="footer">
                  <base-button type="primary" @click="submitJobs()">Save</base-button>
                </template>
              </modal>
            </div>
          </template>


          <!-- Conditionally render the view based on the 'isTableView' flag -->
          <div v-if="isTableView">
            <!-- Render the existing Table View -->
            <a-table :columns="columns" :data-source="jobs" :row-key="record => record.JobId" :pagination="pagination"
              :loading="searchLoading" @change="onTableChange" :scroll="{ x: 'max-content' }">
            </a-table>
          </div>
          <div v-else>
            <!-- Render the Picture View  -->
            <JobPictureView :jobs="jobs" />
          </div>

        </card>
      </div>
    </div>

  </div>
</template>

<script>
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/Inputs/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/index";
import JobService from "../services/JobService";
import { ASelect, ASelectOption, AButton, Table, Pagination } from "ant-design-vue";
import JobPictureView from './JobPictureView.vue';


const jobsColumns = [
  "jobId",
  "year",
  "date",
  "competitionId",
  "jobId"
]

export default {
  components: {
    Card,
    Modal,
    BaseButton,
    BaseInput,
    Table,

    Pagination,
    JobPictureView
  },

  data() {
    return {
      modalJobs: false,
        isTableView: true,

      columns: [
        {
          title: 'JobId',
          dataIndex: 'jobId',
          visible: false,
          //scopedSlots: { customRender: 'jobId' },
          //sorter: (a, b) => a.jobId - b.jobId,
          //sorter: (a, b) => a.jobId.localeCompare(b.jobId),
        },
        {
          title: 'JobNumber',
          dataIndex: 'jobNumber',
          visible: true,
          //scopedSlots: { customRender: 'jobNumber' },
          //sorter: (a, b) => a.jobNumber - b.jobNumber,
          //sorter: (a, b) => a.jobNumber.localeCompare(b.jobNumber),
        },
        {
          title: 'JobName',
          dataIndex: 'jobName',
          visible: true,
          //scopedSlots: { customRender: 'jobName' },
          //sorter: (a, b) => a.jobName - b.jobName,
          //sorter: (a, b) => a.jobName.localeCompare(b.jobName),
        },
        {
          title: 'JobType',
          dataIndex: 'jobType',
          visible: false,
          //scopedSlots: { customRender: 'jobType' },
          //sorter: (a, b) => a.jobType - b.jobType,
          //sorter: (a, b) => a.jobType.localeCompare(b.jobType),
        },
        {
          title: 'Role',
          dataIndex: 'role',
          visible: true,
          //scopedSlots: { customRender: 'role' },
          //sorter: (a, b) => a.role - b.role,
          //sorter: (a, b) => a.role.localeCompare(b.role),
        },
        {
          title: 'Company',
          dataIndex: 'company',
          visible: true,
          //scopedSlots: { customRender: 'company' },
          //sorter: (a, b) => a.company - b.company,
          //sorter: (a, b) => a.company.localeCompare(b.company),
        },
        {
          title: 'IsContract',
          dataIndex: 'isContract',
          visible: false,
          //scopedSlots: { customRender: 'isContract' },
          //sorter: (a, b) => a.isContract - b.isContract,
          //sorter: (a, b) => a.isContract.localeCompare(b.isContract),
        },
        {
          title: 'IsFte',
          dataIndex: 'isFte',
          visible: false,
          //scopedSlots: { customRender: 'isFte' },
          //sorter: (a, b) => a.isFte - b.isFte,
          //sorter: (a, b) => a.isFte.localeCompare(b.isFte),
        },
        {
          title: 'IsFullTime',
          dataIndex: 'isFullTime',
          visible: false,
          //scopedSlots: { customRender: 'isFullTime' },
          //sorter: (a, b) => a.isFullTime - b.isFullTime,
          //sorter: (a, b) => a.isFullTime.localeCompare(b.isFullTime),
        },
        {
          title: 'IsPartTime',
          dataIndex: 'isPartTime',
          visible: false,
          //scopedSlots: { customRender: 'isPartTime' },
          //sorter: (a, b) => a.isPartTime - b.isPartTime,
          //sorter: (a, b) => a.isPartTime.localeCompare(b.isPartTime),
        },
        {
          title: 'NumberOfApplicants',
          dataIndex: 'numberOfApplicants',
          visible: false,
          //scopedSlots: { customRender: 'numberOfApplicants' },
          //sorter: (a, b) => a.numberOfApplicants - b.numberOfApplicants,
          //sorter: (a, b) => a.numberOfApplicants.localeCompare(b.numberOfApplicants),
        },
        {
          title: 'NumberOfRejections',
          dataIndex: 'numberOfRejections',
          visible: false,
          //scopedSlots: { customRender: 'numberOfRejections' },
          //sorter: (a, b) => a.numberOfRejections - b.numberOfRejections,
          //sorter: (a, b) => a.numberOfRejections.localeCompare(b.numberOfRejections),
        },
        {
          title: 'NumberOfSubmissions',
          dataIndex: 'numberOfSubmissions',
          visible: false,
          //scopedSlots: { customRender: 'numberOfSubmissions' },
          //sorter: (a, b) => a.numberOfSubmissions - b.numberOfSubmissions,
          //sorter: (a, b) => a.numberOfSubmissions.localeCompare(b.numberOfSubmissions),
        },
        {
          title: 'ClientId',
          dataIndex: 'clientId',
          visible: false,
          //scopedSlots: { customRender: 'clientId' },
          //sorter: (a, b) => a.clientId - b.clientId,
          //sorter: (a, b) => a.clientId.localeCompare(b.clientId),
        },
        {
          title: 'ClientName',
          dataIndex: 'clientName',
          visible: true,
          //scopedSlots: { customRender: 'clientName' },
          //sorter: (a, b) => a.clientName - b.clientName,
          //sorter: (a, b) => a.clientName.localeCompare(b.clientName),
        },
        {
          title: 'OpenedDate',
          dataIndex: 'openedDate',
          visible: false,
          //scopedSlots: { customRender: 'openedDate' },
          //sorter: (a, b) => a.openedDate - b.openedDate,
          //sorter: (a, b) => a.openedDate.localeCompare(b.openedDate),
        },
        {
          title: 'ClosedDate',
          dataIndex: 'closedDate',
          visible: false,
          //scopedSlots: { customRender: 'closedDate' },
          //sorter: (a, b) => a.closedDate - b.closedDate,
          //sorter: (a, b) => a.closedDate.localeCompare(b.closedDate),
        },
        {
          title: 'AddressId',
          dataIndex: 'addressId',
          visible: false,
          //scopedSlots: { customRender: 'addressId' },
          //sorter: (a, b) => a.addressId - b.addressId,
          //sorter: (a, b) => a.addressId.localeCompare(b.addressId),
        },
        {
          title: 'ImageFile',
          dataIndex: 'imageFile',
          visible: false,
          //scopedSlots: { customRender: 'imageFile' },
          //sorter: (a, b) => a.imageFile - b.imageFile,
          //sorter: (a, b) => a.imageFile.localeCompare(b.imageFile),
        },
        {
          title: 'CityState',
          dataIndex: 'cityState',
          visible: true,
          //scopedSlots: { customRender: 'cityState' },
          //sorter: (a, b) => a.cityState - b.cityState,
          //sorter: (a, b) => a.cityState.localeCompare(b.cityState),
        },
        {
          title: 'Active',
          dataIndex: 'active',
          visible: false,
          //scopedSlots: { customRender: 'active' },
          //sorter: (a, b) => a.active - b.active,
          //sorter: (a, b) => a.active.localeCompare(b.active),
        },
        {
          title: 'Description',
          dataIndex: 'description',
          visible: false,
          //scopedSlots: { customRender: 'description' },
          //sorter: (a, b) => a.description - b.description,
          //sorter: (a, b) => a.description.localeCompare(b.description),
        },
        {
          title: 'LastModified',
          dataIndex: 'lastModified',
          visible: false,
          //scopedSlots: { customRender: 'lastModified' },
          //sorter: (a, b) => a.lastModified - b.lastModified,
          //sorter: (a, b) => a.lastModified.localeCompare(b.lastModified),
        },
        {
          title: 'CreatedOn',
          dataIndex: 'createdOn',
          visible: false,
          //scopedSlots: { customRender: 'createdOn' },
          //sorter: (a, b) => a.createdOn - b.createdOn,
          //sorter: (a, b) => a.createdOn.localeCompare(b.createdOn),
        },
        {
          title: 'CreatedBy',
          dataIndex: 'createdBy',
          visible: false,
          //scopedSlots: { customRender: 'createdBy' },
          //sorter: (a, b) => a.createdBy - b.createdBy,
          //sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
        },
        {
          title: 'CreatedById',
          dataIndex: 'createdById',
          visible: false,
          //scopedSlots: { customRender: 'createdById' },
          //sorter: (a, b) => a.createdById - b.createdById,
          //sorter: (a, b) => a.createdById.localeCompare(b.createdById),
        },
        {
          title: 'SensitivityLevel',
          dataIndex: 'sensitivityLevel',
          visible: false,
          //scopedSlots: { customRender: 'sensitivityLevel' },
          //sorter: (a, b) => a.sensitivityLevel - b.sensitivityLevel,
          //sorter: (a, b) => a.sensitivityLevel.localeCompare(b.sensitivityLevel),
        },
      ],
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total) => `Total ${total} jobs`,
      },

      jobsData: {
        jobId: null,
        year: '',
        date: '',
        competitionId: '',
        jobId: ''
      },

      jobs: [],


      jobsTable: {
        columns: [...jobsColumns],
        data: [],
      },

      // New properties for sorting and searching
      sortBy: 'jobId',           // Column to sort by
      sortOrder: 'asc',     // Sort order (asc or desc)
      searchQuery: '',      // Search query
      searchLoading: false, // Initialize searchLoading property


    };
  },
  watch: {
    searchQuery: {
      handler: "getAllJobs", // Call the fetchData method when searchQuery changes
      immediate: true, // Trigger immediately when the component is mounted
    },
  },

  methods: {

    async submitJobs() {
      const currentDate = new Date().getTime();
      this.jobsData.created = currentDate;

      const jsonData = JSON.stringify(this.jobsData);
      const res = await JobService.addJob(jsonData);

      if (res.status === 200) {
        this.$notify({
          component: NotificationTemplate,
          icon: "tim-icons icon-bell-55",
          type: "success",
          timeout: 3000,
        });

        this.modalJobs = false;
        this.getAllJobs();
      }
    },

    async getAllJobs() {
      this.searchLoading = true; // Set searchLoading to true while fetching data

      const response = await JobService.getAllJobs(this.sortBy, this.sortOrder, this.searchQuery, this.pagination.current,
        this.pagination.pageSize
      );
      this.jobs = response.data.content;
      this.pagination.total = response.data.totalElements;
      this.searchLoading = false;

      console.log(this.jobs);
      let jobsTableData = [];
      for (let i = 0; i < this.jobs.length; i++) {
        jobsTableData.push({
          id: i,
          jobId: this.jobs[i].jobId,
          year: this.jobs[i].year,
          date: this.jobs[i].date,
          competitionId: this.jobs[i].competitionId,
          jobId: this.jobs[i].jobId,
        });

      }
      this.searchLoading = false;


    },
    async onTableChange(pagination, filters, sorter) {
      if (sorter && sorter.field && sorter.order) {
        this.sortBy = sorter.field;
        if (sorter.order == "ascend") {
            this.sortOrder = "asc";
        } else {
            this.sortOrder = "desc";
        }
      }
      if (pagination) {
        this.pagination.current = pagination.current;
        this.pagination.pageSize = pagination.pageSize;
      }

      await this.getAllJobs();
    },

    onSearch(value) {
      console.log(value);
      this.searchQuery = value; // Update searchQuery when the user types
    },

    toggleView() {
      this.isTableView = !this.isTableView;
    },
  },

  mounted() {
    this.getAllJobs();
  }
};
</script>

<style>
.modal-dialog {
  margin-top: -300px;
}
</style>
