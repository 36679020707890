<template>
  <div class="content">


  <h5>{{ this.clientId }}</h5>

<div  style="display: inline-flex;">
  <div><input label="ClientId" type="text" placeholder="Enter ClientId" v-model="clientDetails.clientId"></input></div>
  <div style="width: 5px;"></div>
  <div>ClientId<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="ClientNumber" type="text" placeholder="Enter ClientNumber" v-model="clientDetails.clientNumber"></input></div>
  <div style="width: 5px;"></div>
  <div>ClientNumber<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="ClientName" type="text" placeholder="Enter ClientName" v-model="clientDetails.clientName"></input></div>
  <div style="width: 5px;"></div>
  <div>ClientName<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="ClientType" type="text" placeholder="Enter ClientType" v-model="clientDetails.clientType"></input></div>
  <div style="width: 5px;"></div>
  <div>ClientType<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="ContactName" type="text" placeholder="Enter ContactName" v-model="clientDetails.contactName"></input></div>
  <div style="width: 5px;"></div>
  <div>ContactName<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="NumberOfOpenJobs" type="text" placeholder="Enter NumberOfOpenJobs" v-model="clientDetails.numberOfOpenJobs"></input></div>
  <div style="width: 5px;"></div>
  <div>NumberOfOpenJobs<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="NumberOfClosedJobs" type="text" placeholder="Enter NumberOfClosedJobs" v-model="clientDetails.numberOfClosedJobs"></input></div>
  <div style="width: 5px;"></div>
  <div>NumberOfClosedJobs<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="NumberOfPlacements" type="text" placeholder="Enter NumberOfPlacements" v-model="clientDetails.numberOfPlacements"></input></div>
  <div style="width: 5px;"></div>
  <div>NumberOfPlacements<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="NumberOfApplicants" type="text" placeholder="Enter NumberOfApplicants" v-model="clientDetails.numberOfApplicants"></input></div>
  <div style="width: 5px;"></div>
  <div>NumberOfApplicants<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="NumberOfRejections" type="text" placeholder="Enter NumberOfRejections" v-model="clientDetails.numberOfRejections"></input></div>
  <div style="width: 5px;"></div>
  <div>NumberOfRejections<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="NumberOfSubmissions" type="text" placeholder="Enter NumberOfSubmissions" v-model="clientDetails.numberOfSubmissions"></input></div>
  <div style="width: 5px;"></div>
  <div>NumberOfSubmissions<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="AddressId" type="text" placeholder="Enter AddressId" v-model="clientDetails.addressId"></input></div>
  <div style="width: 5px;"></div>
  <div>AddressId<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="ImageFile" type="text" placeholder="Enter ImageFile" v-model="clientDetails.imageFile"></input></div>
  <div style="width: 5px;"></div>
  <div>ImageFile<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="CityState" type="text" placeholder="Enter CityState" v-model="clientDetails.cityState"></input></div>
  <div style="width: 5px;"></div>
  <div>CityState<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="Active" type="text" placeholder="Enter Active" v-model="clientDetails.active"></input></div>
  <div style="width: 5px;"></div>
  <div>Active<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="Description" type="text" placeholder="Enter Description" v-model="clientDetails.description"></input></div>
  <div style="width: 5px;"></div>
  <div>Description<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="LastModified" type="text" placeholder="Enter LastModified" v-model="clientDetails.lastModified"></input></div>
  <div style="width: 5px;"></div>
  <div>LastModified<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="CreatedOn" type="text" placeholder="Enter CreatedOn" v-model="clientDetails.createdOn"></input></div>
  <div style="width: 5px;"></div>
  <div>CreatedOn<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="CreatedBy" type="text" placeholder="Enter CreatedBy" v-model="clientDetails.createdBy"></input></div>
  <div style="width: 5px;"></div>
  <div>CreatedBy<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="CreatedById" type="text" placeholder="Enter CreatedById" v-model="clientDetails.createdById"></input></div>
  <div style="width: 5px;"></div>
  <div>CreatedById<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="SensitivityLevel" type="text" placeholder="Enter SensitivityLevel" v-model="clientDetails.sensitivityLevel"></input></div>
  <div style="width: 5px;"></div>
  <div>SensitivityLevel<br><br></div>
</div>
<br>


  <div>
      <base-button class="btn btn-primary" @click="updateClient()">Save</base-button>
  </div>

</div>
</template>
<script>

import ClientService from "../services/ClientService";
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/Inputs/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/index";
import { ASelect, ASelectOption, AButton, Table, Pagination } from "ant-design-vue";
import { VueAutosuggest } from "vue-autosuggest";

export default {
  props: {
    clientId: {
      type: String,
      required: true
    }
  },
  components: {
		VueAutosuggest
  },
  data() {
    return {
      clientDetails: null,
    };
  },
  methods: {

    
    async updateClient() {

      const jsonData = JSON.stringify(this.clientDetails);
      const res = await ClientService.update(jsonData);
	


      if (res.status === 200) {
//        this.$notify({
//          component: NotificationTemplate,
//          icon: "tim-icons icon-bell-55",
//          type: "success",
//          timeout: 3000,
//        });

//        this.modalClients = false;
//        this.getAllClients();
      }
    },

    async getClientDetails() {
      try {
	    let response = await ClientService.get(this.clientId);
	    this.clientDetails = response.data;
      } catch (error) {
        console.error('Error fetching client details:', error);
      }
    }
  },
  mounted() {
    this.getClientDetails();
  },
  computed: {
  },
  
};
</script>
<style>
.input {
	text-align: center;
}
#autosuggest__input {
  display: inline-block;
}
.autosuggest__results-container {
	position: relative;
}
	.autosuggest__results {
	font-weight: 300;
	margin: 0;
	position: absolute;
	z-index: 10000001;
	border: 1px solid #e0e0e0;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	background: white;
}
	.autosuggest__results ul {
	list-style: none;
	padding-left: 0;
	margin: 0;
}
.autosuggest__results .autosuggest__results-item {
	cursor: pointer;
	padding: 5px;
}
#autosuggest ul:nth-child(1) > .autosuggest__results_title {
	border-top: none;
}
.autosuggest__results .autosuggest__results_title {
	color: gray;
	font-size: 11px;
	margin-left: 0;
	padding: 15px 13px 5px;
	border-top: 1px solid lightgray;
}
.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results
.autosuggest__results-item.autosuggest__results-item--highlighted {
	background-color: #F6F6F6;
}
</style>


