import http from "../http-common";

class CandidateService {
  getAllCandidates(sortBy = "", sortOrder = "", searchQuery = "", currentPage = "", pageSize = "") {
    console.log(sortBy, sortOrder, searchQuery, currentPage, pageSize)
    return http.get(`/candidate/candidates?page=${currentPage-1}&size=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&searchQuery=${searchQuery}`);
  }

  get(candidateId) {
  	return http.get(`/candidate/${candidateId}`);
  }

  findByField(matchData) {
  	return http.get(`/candidate?field=${matchData}`);
  }

  addCandidate(data) {
    return http.post("/candidate/addCandidate", data);
  }

  update(data) {
  	return http.post("/candidate/updateCandidate", data);
  }
}

export default new CandidateService();
