<template>
  <div class="content">


  <h5>{{ this.submissionId }}</h5>

<div  style="display: inline-flex;">
  <div><input label="SubmissionId" type="text" placeholder="Enter SubmissionId" v-model="submissionDetails.submissionId"></input></div>
  <div style="width: 5px;"></div>
  <div>SubmissionId<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="SubmissionNumber" type="text" placeholder="Enter SubmissionNumber" v-model="submissionDetails.submissionNumber"></input></div>
  <div style="width: 5px;"></div>
  <div>SubmissionNumber<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="JobId" type="text" placeholder="Enter JobId" v-model="submissionDetails.jobId"></input></div>
  <div style="width: 5px;"></div>
  <div>JobId<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="CandidateId" type="text" placeholder="Enter CandidateId" v-model="submissionDetails.candidateId"></input></div>
  <div style="width: 5px;"></div>
  <div>CandidateId<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="ClientName" type="text" placeholder="Enter ClientName" v-model="submissionDetails.clientName"></input></div>
  <div style="width: 5px;"></div>
  <div>ClientName<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="JobName" type="text" placeholder="Enter JobName" v-model="submissionDetails.jobName"></input></div>
  <div style="width: 5px;"></div>
  <div>JobName<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="CandidateName" type="text" placeholder="Enter CandidateName" v-model="submissionDetails.candidateName"></input></div>
  <div style="width: 5px;"></div>
  <div>CandidateName<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="CandidateType" type="text" placeholder="Enter CandidateType" v-model="submissionDetails.candidateType"></input></div>
  <div style="width: 5px;"></div>
  <div>CandidateType<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="StartDate" type="text" placeholder="Enter StartDate" v-model="submissionDetails.startDate"></input></div>
  <div style="width: 5px;"></div>
  <div>StartDate<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="EndDate" type="text" placeholder="Enter EndDate" v-model="submissionDetails.endDate"></input></div>
  <div style="width: 5px;"></div>
  <div>EndDate<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="ImageFile" type="text" placeholder="Enter ImageFile" v-model="submissionDetails.imageFile"></input></div>
  <div style="width: 5px;"></div>
  <div>ImageFile<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="JobNumber" type="text" placeholder="Enter JobNumber" v-model="submissionDetails.jobNumber"></input></div>
  <div style="width: 5px;"></div>
  <div>JobNumber<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="CityState" type="text" placeholder="Enter CityState" v-model="submissionDetails.cityState"></input></div>
  <div style="width: 5px;"></div>
  <div>CityState<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="Active" type="text" placeholder="Enter Active" v-model="submissionDetails.active"></input></div>
  <div style="width: 5px;"></div>
  <div>Active<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="Description" type="text" placeholder="Enter Description" v-model="submissionDetails.description"></input></div>
  <div style="width: 5px;"></div>
  <div>Description<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="LastModified" type="text" placeholder="Enter LastModified" v-model="submissionDetails.lastModified"></input></div>
  <div style="width: 5px;"></div>
  <div>LastModified<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="CreatedOn" type="text" placeholder="Enter CreatedOn" v-model="submissionDetails.createdOn"></input></div>
  <div style="width: 5px;"></div>
  <div>CreatedOn<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="CreatedBy" type="text" placeholder="Enter CreatedBy" v-model="submissionDetails.createdBy"></input></div>
  <div style="width: 5px;"></div>
  <div>CreatedBy<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="CreatedById" type="text" placeholder="Enter CreatedById" v-model="submissionDetails.createdById"></input></div>
  <div style="width: 5px;"></div>
  <div>CreatedById<br><br></div>
</div>
<br>
<div  style="display: inline-flex;">
  <div><input label="SensitivityLevel" type="text" placeholder="Enter SensitivityLevel" v-model="submissionDetails.sensitivityLevel"></input></div>
  <div style="width: 5px;"></div>
  <div>SensitivityLevel<br><br></div>
</div>
<br>


  <div>
      <base-button class="btn btn-primary" @click="updateSubmission()">Save</base-button>
  </div>

</div>
</template>
<script>

import SubmissionService from "../services/SubmissionService";
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/Inputs/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/index";
import { ASelect, ASelectOption, AButton, Table, Pagination } from "ant-design-vue";
import { VueAutosuggest } from "vue-autosuggest";

export default {
  props: {
    submissionId: {
      type: String,
      required: true
    }
  },
  components: {
		VueAutosuggest
  },
  data() {
    return {
      submissionDetails: null,
    };
  },
  methods: {

    
    async updateSubmission() {

      const jsonData = JSON.stringify(this.submissionDetails);
      const res = await SubmissionService.update(jsonData);
	


      if (res.status === 200) {
//        this.$notify({
//          component: NotificationTemplate,
//          icon: "tim-icons icon-bell-55",
//          type: "success",
//          timeout: 3000,
//        });

//        this.modalSubmissions = false;
//        this.getAllSubmissions();
      }
    },

    async getSubmissionDetails() {
      try {
	    let response = await SubmissionService.get(this.submissionId);
	    this.submissionDetails = response.data;
      } catch (error) {
        console.error('Error fetching submission details:', error);
      }
    }
  },
  mounted() {
    this.getSubmissionDetails();
  },
  computed: {
  },
  
};
</script>
<style>
.input {
	text-align: center;
}
#autosuggest__input {
  display: inline-block;
}
.autosuggest__results-container {
	position: relative;
}
	.autosuggest__results {
	font-weight: 300;
	margin: 0;
	position: absolute;
	z-index: 10000001;
	border: 1px solid #e0e0e0;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	background: white;
}
	.autosuggest__results ul {
	list-style: none;
	padding-left: 0;
	margin: 0;
}
.autosuggest__results .autosuggest__results-item {
	cursor: pointer;
	padding: 5px;
}
#autosuggest ul:nth-child(1) > .autosuggest__results_title {
	border-top: none;
}
.autosuggest__results .autosuggest__results_title {
	color: gray;
	font-size: 11px;
	margin-left: 0;
	padding: 15px 13px 5px;
	border-top: 1px solid lightgray;
}
.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results
.autosuggest__results-item.autosuggest__results-item--highlighted {
	background-color: #F6F6F6;
}
</style>


